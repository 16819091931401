import React from "react";
import { Container } from "nes-react";
import ReactTypingEffect from 'react-typing-effect';
import Delayed from './Delayed';
import Submit from './SubmitMsg';
import './App.css'; // Tell webpack that Button.js uses these styles
import './config.js'; //Load Variables'
import heart from './img/heart.png';

function App() {

    return (
    <div className="App-body">
      <img src={heart} width="80" height="80" alt="Heart"/>
      <Container>
        <ReactTypingEffect
        text={[global.msg1]}
        eraseSpeed={1000000000}
        />
          <Delayed waitBeforeShow={global.delayMsg1}>
            <Submit>
            </Submit>
          </Delayed>
      </Container>
    </div>
  );
  
}

export default App;