import React from "react";
import { Container } from "react-bootstrap";
import './App.css'; // Tell webpack that Button.js uses these styles
import './config.js'; //Load Variables
import us from './img/us.png';

function App() {

    return (
    <div className="App-body">
      <img src={us} width="200" height="200" alt="us"/>
      <Container>
        Eu criei este site para te pedir em casamento meu amor!
        <br/>Mariana C&#226;mara de Oliveira &#38; Luciano Ferreira Assad :-)
      </Container>
    </div>
  );
  
}

export default App;