import React, { Component } from 'react';
import { Button } from "nes-react";

import { withRouter } from 'react-router-dom'; // <--- import `withRouter`. We will use this in the bottom of our file.

import './config.js'; //Load Variables


const envioMenssagens = async (e) => {

    let requests = [];
  
    for (let i = 0; i < global.agenda.length; i++)   { 
  
      fetch("api/sendMessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ to: '+5511'+global.agenda[i], body: "Ola "+global.nome[i]+", "+global.msgbody }),
     }).then(async (response)=>{
        const body = await response.text();
        if (response.status !== 200) throw Error(body.message);
        // if everything is fine resiolve to true or for example your body content
        return Promise.resolve(true);
     }).catch((e)=>{
        // if we encounter an error resolve to false
        console.error('there was an error:', e.message)
        return Promise.resolve(false);
  })
    
  }; 
  
      // wait until all requests are done!
      await Promise.all(requests).then((results)=>{
        // here we have all the results
        console.log('all requests finished!', results);
        for(let i = 0; i < requests.length; i++){
          console.log(i, 'request resultet in', results[i]);
        }
    })
    
  };

class ContactForm extends Component {

  submitForm (e) {

    envioMenssagens();
    e.preventDefault()
    this.props.history.push('/2'); // <--- The page you want to redirect your user to.
  }

  render() {
    return (
      <div>
        <form onSubmit={this.submitForm.bind(this)}>
          <Button type="submit">Continuar</Button>
        </form>
      </div>
    );
  }
}

export default withRouter(ContactForm); // <--- make sure to wrap your component with `withRouter()`