//Main Page Variables
//----------------
global.msg1 = "Oi minha nenis! Eu faço site né? Então mais que justo que eu faça um para você! Né? Parabéns amor da minha vida! Muita saúde, felicidades (ao meu lado) e tudo de bom sempre! Agora peça para que TODOS aqui peguem seus celulares para continuarmos o jogo e clique em continuar...  ";
global.delayMsg1=65000;
//Second Page Variables
//----------------
global.msg2 = "Nenis, este não é um site qualquer... Vamos lá... Eu tenho um mega orgulho de você! Minha batalhadora, generosa, inteligente, maravilhosa e com o coração mais lindo que eu já vi! Quero você para sempre ao meu lado e construindo coisas lindas! Eu já te falei isso, mas Deus foi muito generoso em ter juntado as nossas vidas! Meu coração é seu e TE AMO mais que tudo! Agora clique em continuar e vamos todos aproveitar este momento! ";
global.delayMsg2=92000;
global.url="https://youtu.be/5bN5b11H4_s";

//Contacts
//---------------
global.agenda = [];
global.nome = [];
global.msgbody = "não manifeste a surpresa e ligue a camera do celular para registrar este momento, eu vou pedir a Mariana em casamento!";
