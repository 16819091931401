import React from "react";
import { Container } from "react-bootstrap";
import ReactTypingEffect from 'react-typing-effect';
import Delayed from './Delayed';
import { Button } from "nes-react";
import './App.css'; // Tell webpack that Button.js uses these styles
import './config.js'; //Load Variables
import heart from './img/heart.png';

function App() {

    return (
    <div className="App-body">
      <img src={heart} width="80" height="80" alt="Heart"/>
      <Container>
        <ReactTypingEffect
        text={[global.msg2]}
        eraseSpeed={1000000000}
        />
          <Delayed waitBeforeShow={global.delayMsg2}>
            <div>
                <form method="https" action={global.url}>
                <Button type="submit">Continuar</Button>
                </form>
            </div>
          </Delayed>
      </Container>
    </div>
  );
  
}

export default App;