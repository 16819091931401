import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import MainPage from "./MainPage";
import Page2 from "./Page2";
import About from "./About";
import NotFound from "./404";

function App() {
  return (
    <div className="App">
      <Router>
      <Switch>
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/sobre">
            <About />
          </Route>
          <Route path="/2">
            <Page2 />
          </Route>
          <Route path="/api/sendMessage.js" target="_self">
          </Route>
          <Route path="/*">
           <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  
  )
  
}

export default App;