import React from "react";
import { Container } from "react-bootstrap";
import './App.css'; // Tell webpack that Button.js uses these styles
import './config.js'; //Load Variables
import us from './img/us.png';
import heart from './img/heart.png';

function App() {

    return (
    <div className="App-body">
      <img src={heart} width="80" height="80" alt="Heart"/> 
      404 - P&#225;gina n&#227;o encontrada!
      <img src={us} width="200" height="200" alt="us"/>
      <Container>
        <br/>Mariana C&#226;mara de Oliveira &#38; Luciano Ferreira Assad
      </Container>
    </div>
  );
  
}

export default App;